<template>
    <BaseTag
        class="entry-metadata__type"
        data-background="fenex"
    >
        <BaseIcon
            v-if="showLock"
            icon="lock"
        />

        <img
            src="~/assets/img/fenex-logo.svg"
            alt="Fenex"
            loading="eager"
            fetchpriority="high"
            width="62"
            height="12"
        >
        <span class="sr-only">Fenex</span>
    </BaseTag>
</template>

<script setup>
defineProps({
    showLock: {
        type: Boolean,
        default: false
    }
});
</script>

<style lang="less">
</style>
