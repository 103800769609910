<template>
    <component
        :is="element"
        class="base-tag"
    >
        <slot />

        <div
            v-if="!!$slots.dropdown"
            class="base-tag__dropdown"
        >
            <div class="base-tag__dropdown__body">
                <slot name="dropdown" />
            </div>
        </div>
    </component>
</template>

<script setup>
defineProps({
    element: {
        type: [String, Object],
        default: 'div'
    }
});
</script>

<style src="./BaseTag.less" lang="less"></style>
